<template>
  <div>
    <div class="page-wrapper chiller-theme" style="margin-top: 71px">
      <main class="page-content">
        <div class="container-fluid">
          <ul id="breadcrumb" class="breadcrumb ProximaNovaRegular mx-4" :class="{introBread: isIntroBreadcrumb}">
            <li>
              <span>Solutions</span>
            </li>
            <li class="ProximaNovaBold">Agile</li>
          </ul>
        </div>
        <div class="container mb-5" style="margin-top: 25px">
          <div
            class="
              col-sm-12 col-xs-12 col-md-12 col-lg-12
              d-flex
              justify-center
            "
          >
            <div class="col-sm-12 col-md-8 col-xs-12 col-lg-8">
              <div class="row">
                <div
                  class="w-100"
                  style="height: 209px; margin-top: 24px"
                  v-for="(obj, index) in dataObj"
                  :key="index"
                >
                  <w-data-type-card
                    :dataType="obj.name"
                    :text="obj.description"
                    :image="obj.image"
                    @dataselected="showWelcomePage()"
                  ></w-data-type-card>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <modal
        name="newWelcomePage"
        :width="388"
        class="welcome--modal"
        @closed="hideWelcomePage"
        :clickToClose="false"
        v-show="isStartNewModal"
      >
        <div class="">
          <div class="w-100">
            <div class="row modal-padding">
              <div class="col p-0 welcome ml-4 mt-4 align-start">
                <img src="../../assets/Icons/group-21.png" alt class="welcome--image" />
              </div>
              <div class="col p-0 intro__content">
                <p class="Welcome-to-Data-Poem ProximaNovaRegular">
                  Continue from where you left?
                </p>
                <p class="Together-let-us-hel ProximaNovaRegular">
                  Hey Aditya, you can continue your work from
                    where you had left last time!
                </p>

                <button
                  color="primary isi"
                  class="btn-effect-intro"
                  size="button--size-m"
                  @click="gotoPlatform($event)"
                >
                  <span class="btn-text">Continue</span>
                  <i
                    class="fas fa-arrow-right ml-5 pl-4 d-flex"
                    style="align-items: center"
                  ></i>
                </button>

                <span class="start-from-fresh mt-4" @click="hideWelcomePage">No, start from fresh</span>

              </div>
            </div>
          </div>
        </div>
      </modal>
      </main>
    </div>
  </div>
</template>
<script>
import DataTypeCard from "@/widgets/DataTypeCard.vue";
export default {
    props: [],
    components: {
        "w-data-type-card": DataTypeCard,
    },
    data() {
        return {
        isShow:false,
        isActive: false,
        padding: 0,
        menuMargin: 70,
        username: "Test",
        dataObj: [
            {
                name: "Corporate Data",
                description:
                    "Use corporate data to create media ROI at business level.",
                image: "corporate.webp",
            },
            {
                name: "Product Data",
                description: "Use product data to create media ROI at product level.",
                image: "product.webp",
            },
        ],
        isIntroBreadcrumb: false,
        };
    },
    computed: {
        isStartNewModal() {
            return this.isShow;
        },
    },
    methods: {
        gotoPlatform(e) {
            console.log(e);
            this.$router.push("/solutions/agile/platform");
        },
        hideWelcomePage() {
            this.$modal.hide("newWelcomePage");
        },
        showWelcomePage() {
            this.isShow = true; 
            this.$modal.show("newWelcomePage");
        },
    },
    created(){
        sessionStorage.removeItem('kpiMarket')
    }
};
</script>
<style scoped>
.container {
  height: 100%;
}
.breadcrumb {
  background-color: transparent;
  margin-top: 32px;
}
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
}
.button {
  padding: 0.75rem;
  height: 60px;
  float: right;
  cursor: pointer;
  background-color: #050505;
  color: #fff;
  text-decoration: none;
  border-radius: 4px;
}
.btn-effect {
  width: 190px;
  height: 60px;
  font-family: ProximaNovaRegular;
  background-color: #cdcdcd;
  color: #f3f3f3;
  border: none;
  text-align: left;
  font-size: 16px;
  padding-left: 20px;
}

.btn-effect i {
  font-size: 14px;
  float: right;
  margin-top: 7px;
  margin-right: 10px;
}

.btn-effect:hover {
  background-color: #050505;
  color: #f3f3f3;
}
.introBread {
  position: fixed;
  z-index: 1111;
  padding: 30px;
  background: #fff;
}
span.start-from-fresh {
    font-size: 16px;
    color: #050505;
    text-decoration: underline;
    cursor: pointer;
    font-family: ProximaNovaRegular;
}
.Welcome-to-Data-Poem{
    color: #222a37;
    font-family: ProximaNovaBold;
    font-size: 32px;
    font-weight: bold;
}
.Together-let-us-hel{
    color: #9aafd4;
    font-family: ProximaNovaRegular;
    font-size: 16px;
    margin-top: 25px;
}
</style>
